<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 bookings-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: 'r_bookings'
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("bookings.bookings.create") }}
        </div>
        <template v-slot:right v-if="valid">
          <button
            @click="
              showModal = true;
              if (this.selectedResourceIds.length == 1)
                this.bookingCost(bookingCostData);
            "
          >
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <!-- Is-private flag -->
    <ul class="clebex-item-section">
      <li class="clebex-item-section-item">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label larger-label">
              {{ displayLabelName("bookings.bookings.is-private") }}
            </label>
          </div>
          <div class="checkbox slide">
            <input id="is-private" type="checkbox" v-model="isPrivate" />
            <label for="is-private"></label>
          </div>
        </div>
      </li>
    </ul>
    <!-- Subject -->
    <ul class="clebex-item-section">
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-section-input">
          <label class="clebex-section-input-label">{{
            displayLabelName("bookings.bookings.subject")
          }}</label>
          <input type="text" v-model="subject" />
        </div>
      </li>
    </ul>
    <!-- Users -->
    <section v-if="can('BOOKINGS')">
      <users-section> </users-section>
    </section>
    <!-- Date  -->
    <ul @click="selectSlots()" class="clebex-item-section cursor-pointer">
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-section-input">
          <label class="clebex-section-input-label cursor-pointer">{{
            displayLabelName("global.calendar.date")
          }}</label>
          <p class="text-label ellipsis">
            {{
              displayDate(
                selectedSlot ? selectedSlot.slots[0].datetime_from : null
              )
            }}
          </p>
        </div>
      </li>
      <li class="clebex-item-section-item time">
        <div class="clebex-item-content-wrapper">
          <ul class="clebex-item-content-range">
            <li class="clebex-item-range from">
              <div class="clebex-item-range-cta">
                {{
                  displayTime(
                    selectedSlot ? selectedSlot.slots[0].datetime_from : null
                  )
                }}
              </div>
            </li>
            <li class="clebex-item-range separator">
              <icon icon="#cx-app1-time" width="16" height="16" />
            </li>
            <li class="clebex-item-range to">
              <div class="clebex-item-range-cta">
                {{
                  displayTime(
                    selectedSlot
                      ? selectedSlot.slots.slice(-1)[0].datetime_to
                      : null
                  )
                }}
              </div>
            </li>
          </ul>
        </div>
      </li>
    </ul>
    <!-- Resource selection -->
    <ul
      v-if="declarableResources && declarableResources.length"
      class="clebex-item-section overflow-hidden"
    >
      <li
        class="clebex-item-section-item"
        :class="collapse['resources'] ? 'collapsed' : 'expanded'"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label larger-label">
              {{ displayLabelName("bookings.bookings.resources") }}
            </label>
          </div>
          <span
            class="follow-up-icons cursor-pointer"
            @click="
              collapse['resources']
                ? (collapse['resources'] = false)
                : (collapse['resources'] = true)
            "
          >
            <span class="follow-up-icon-item collapsable"
              ><icon icon="#cx-app1-arrow-right-12x12" width="12" height="12"
            /></span>
          </span>
        </div>
      </li>
      <section :class="collapse['resources'] ? 'collapsed' : 'expanded ten'">
        <li
          v-for="resource in declarableResources.filter(el =>
            selectedResourceIds.includes(el.id)
          )"
          :key="resource.id"
          class="clebex-item-section-item no-bottom-border light no-separator"
        >
          <div
            @click="selectResources(resource.id)"
            class="clebex-item-content-wrapper cursor-pointer"
          >
            <div class="clebex-section-input">
              <label class="clebex-section-input-label cursor-pointer"
                >{{
                  resource.translations.find(el => el.locale === locale)
                    ? resource.translations.find(el => el.locale === locale)
                        .name
                    : resource.name
                }}
              </label>
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <div class="svg-icon">
                  <svg
                    width="12"
                    height="12"
                    style="width: 12px; height: 12px;"
                  >
                    <use xlink:href="#cx-app1-checkmark"></use>
                  </svg>
                </div>
              </span>
            </span>
          </div>
        </li>
        <li
          v-for="resource in declarableResources.filter(
            el => !selectedResourceIds.includes(el.id)
          )"
          :key="resource.id"
          class="clebex-item-section-item no-bottom-border light no-separator"
        >
          <div
            @click="selectResources(resource.id)"
            class="clebex-item-content-wrapper cursor-pointer"
          >
            <div class="clebex-section-input">
              <label class="clebex-section-input-label cursor-pointer"
                >{{
                  resource.translations.find(el => el.locale === locale)
                    ? resource.translations.find(el => el.locale === locale)
                        .name
                    : resource.name
                }}
              </label>
            </div>
          </div>
        </li>
      </section>
    </ul>
    <ul
      v-if="
        selectedSlot && (!declarableResources || !declarableResources.length)
      "
      class="clebex-item-section overflow-hidden"
    >
      <li class="clebex-item-section-item">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label larger-label">
              {{ displayLabelName("bookings.bookings.resources") }}
            </label>
          </div>
          <span class="follow-up-icons">
            <span class="follow-up-icon-item"
              ><icon icon="#cx-men1-warning" width="16" height="16"
            /></span>
          </span>
        </div>
      </li>
      <li class="clebex-item-section-item no-bottom-border light no-separator">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label"
              >{{ displayLabelName("bookings.bookings.no-resources") }}
            </label>
          </div>
        </div>
      </li>
    </ul>
    <screen-modal
      v-if="showModal"
      class="confirm-modal"
      type="success"
      :confirm-button-label="displayLabelName('global.buttons.ok')"
      :cancel-button-label="displayLabelName('global.buttons.cancel')"
      :confirm-action="declareSlots"
      :show="showModal"
      @close="showModal = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("bookings.bookings.declaration-confirmed") }}
      </h3>
      <p class="text" v-if="cost">
        {{ displayLabelName("plan", "plan", "cost") }}: {{ cost }}
      </p>
      <template v-if="selectedSlot">
        <p v-if="['DAY', 'HALF_DAY'].includes(selectedSlot.type)" class="text">
          {{ displayDate(selectedSlot.slots[0].datetime_from) }}
        </p>
        <p v-else>
          {{ displayTime(selectedSlot.slots[0].datetime_from) }} -
          {{ displayTime(selectedSlot.slots.slice(-1)[0].datetime_to) }}
        </p>
        <p v-for="resource in selectedResourceIds" :key="resource" class="text">
          {{
            declarableResources
              .find(el => el.id === resource)
              .translations.find(el => el.locale === locale)
              ? declarableResources
                  .find(el => el.id === resource)
                  .translations.find(el => el.locale === locale).name
              : declarableResources.find(el => el.id === resource).name
          }}
        </p>
      </template>
    </screen-modal>
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapGetters, mapState, mapActions } from "vuex";
import { formatDate } from "@/services/helpers";
import { DateTime } from "luxon";
import { getLang } from "@/services/http-service";

export default {
  name: "BookingsDeclarationCreate",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    UsersSection: defineAsyncComponent(() =>
      import("@/components/bookings/UsersSection")
    )
  },
  data() {
    return {
      showModal: false,
      subject: "",
      collapse: {},
      selectedResourceIds: [],
      isPrivate: false
    };
  },
  created() {
    if (!this.preset) {
      this.$router.push({ name: "r_bookings" });
    } else {
      this.$store.commit("bookings/setHostId", this.userData.data.id, {
        root: true
      });
    }
  },
  computed: {
    ...mapState("bookings", [
      "preset",
      "declarableResources",
      "hostId",
      "cost"
    ]),
    ...mapGetters("settings", ["globalDateFormat", "globalTimeFormat"]),
    ...mapState("plan", ["selectedSlot"]),
    ...mapState("user", ["userData"]),
    locale() {
      return getLang();
    },
    valid() {
      if (
        this.subject &&
        this.selectedSlot &&
        this.selectedSlot.slots &&
        this.selectedSlot.slots.length &&
        this.selectedSlot.type &&
        this.selectedResourceIds.length &&
        this.hostId
      ) {
        return true;
      }
      return false;
    },
    bookingCostData() {
      return {
        resource_id: this.selectedResourceIds[0],
        datetime_from: this.selectedSlot.slots[0].datetime_from,
        datetime_to: this.selectedSlot.slots.slice(-1)[0].datetime_to
      };
    }
  },
  methods: {
    ...mapActions("bookings", [
      "getBooking",
      "getResourcesAvailability",
      "setDeclaration",
      "bookingCost"
    ]),
    ...mapActions("plan", ["setSelectedSlot"]),
    displayDate(date) {
      if (!date) {
        return this.displayLabelName("global", "calendar", "select-date");
      }
      return formatDate(date, this.globalDateFormat);
    },
    displayTime(time) {
      if (!time) {
        return this.displayLabelName("global", "calendar", "time-slot");
      }
      // Luxon fix for am/pm... remove when globalDateFormat is Luxon oriented
      let timeFormat = this.globalTimeFormat;
      if (timeFormat.slice(-1) === "A") {
        timeFormat = timeFormat.replace(/.$/, "a");
      }
      return DateTime.fromISO(time).toFormat(timeFormat || "HH:mm");
    },
    declareSlots() {
      const postObject = {
        subject: this.subject,
        resource_ids: this.selectedResourceIds,
        type: this.selectedSlot.type,
        slots: this.selectedSlot.slots,
        host_id: this.hostId,
        is_private: this.isPrivate
      };
      this.setDeclaration(postObject).then(() => {
        this.selectedResourceIds = [];
        if (this.selectedSlot) {
          this.getResourcesAvailability({
            slots: this.selectedSlot,
            preset: this.preset,
            declarationId: null,
            hostId: this.hostId
          });
        }
      });
    },
    selectSlots() {
      this.$router.push({ name: "r_bookings-declaration-create-select-slots" });
    },
    selectResources(id) {
      if (this.selectedResourceIds.includes(id)) {
        this.selectedResourceIds = this.selectedResourceIds.filter(
          el => el !== id
        );
      } else {
        this.selectedResourceIds.push(id);
      }
    }
  },
  watch: {
    selectedSlot(val) {
      this.selectedResourceIds = [];
      if (val) {
        this.getResourcesAvailability({
          slots: val,
          preset: this.preset,
          declarationId: null,
          hostId: this.hostId
        });
      } else {
        this.$store.commit("bookings/setDeclarableResources", null, {
          root: true
        });
      }
    },
    preset: {
      deep: true,
      handler() {
        this.setSelectedSlot(null);
        this.selectedResourceIds = [];
        this.$store.commit("bookings/setDeclarableResources", null, {
          root: true
        });
      }
    }
  },
  beforeUnmount() {
    this.$store.commit("bookings/setPreset", null, {
      root: true
    });
    this.$store.commit("bookings/setDeclarableResources", null, {
      root: true
    });
    this.setSelectedSlot(null);
  }
};
</script>
